<script setup>
	// Libraries
	import { storeToRefs } from 'pinia';

	// Stores
	import { useGlobalStore } from '@/stores/global';

	// Props
	const { headline, backgroundColor } = defineProps({
		headline: {
			type: String,
			default: 'announcements',
		},
		backgroundColor: {
			type: String,
			default: 'bg-white',
		},
	});

	// Store Data
	const { announcements } = storeToRefs(useGlobalStore());
</script>

<template>
	<section class="block block-announcement" :class="backgroundColor" v-if="announcements?.data?.length >= 1">
		<div class="container">
			<span
				class="title"
				v-html="headline"
				:style="`--title-color:${backgroundColor == 'bg-red' ? 'var(--white)' : 'var(--red)'};`"
			/>
			<div
				class="messages rich-text"
				:style="`--text-color:${backgroundColor == 'bg-red' ? 'var(--white)' : 'var(--grey-900)'};`"
			>
				<div class="message" v-for="announcement in announcements.data" :key="announcement.data.message">
					<div class="message__copy" v-html="announcement.data.message" />
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss">
	.block-announcement {
		&.bg-red {
			background: var(--red);
			a {
				color: var(--white);
				&:hover {
					color: var(--white);
					border-bottom: transparent;
				}
				&:visited {
					color: var(--white);
					border-bottom: var(--white);
					&:hover {
						color: var(--white);
						border-bottom: transparent;
					}
				}
			}
		}
		padding: 3rem 0;

		.container {
			display: flex;
			flex-direction: column;
			gap: 1rem;

			@media (min-width: $mobile) {
				flex-direction: row;
			}

			.title {
				color: var(--title-color);
				font-size: 20px;
				font-style: normal;
				font-weight: 900;
				line-height: 123.1%; /* 24.62px */
				letter-spacing: 1.2px;
				text-transform: uppercase;
			}

			.messages {
				color: var(--text-color);
				font-size: 20px;
				font-style: normal;
				font-weight: 350;
				line-height: 135.6%; /* 27.12px */
				letter-spacing: 0.3px;

				.message {
					+ .message {
						margin-top: 1rem;
					}
				}
			}
		}
	}
</style>
